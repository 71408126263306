import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

type Props = {
  description: string
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0 20px;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0 0 20px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledText = styled(Text)`
  ${({ theme }) => theme.media.lg.min} {
    max-width: 50%;
  }
`

const About: React.FC<Props> = ({ description }) => {
  return (
    <Section>
      <Container>
        <StyledText
          size={18}
          weight={600}
          themecolor="black"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Container>
    </Section>
  )
}

export default About
