import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/Katalogi-Dokumenty-Certyfikaty/About'
import Tiles from 'components/layout/Sections/Pages/Katalogi-Dokumenty-Certyfikaty/Tiles'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Dokumenty do pobrania',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Dokumenty do pobrania',
    },
  ],
}

const DokumentyDoPobraniaPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.dokumentyDoPobrania
  const PAGE_SEO = data?.wpPage?.seo

  const DOCUMENTS = React.useMemo(
    () =>
      PAGE?.documentsList?.map((document) => ({
        img: document?.documentsListDocumentImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: document?.documentsListDocumentImg?.altText,
        downloadLink: document?.documentsListDocumentLink,
        title: document?.documentsListDocumentTitle,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.documentsImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.documentsImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.documentsTitle}
        headingDesktop={PAGE?.documentsTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.documentsDescription} />
      <Tiles catalogues={DOCUMENTS} />
    </Layout>
  )
}

export default DokumentyDoPobraniaPage

export const query = graphql`
  query DokumentyDoPobraniaPage {
    wpPage(slug: { regex: "/dokumenty-do-pobrania/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      dokumentyDoPobrania {
        documentsTitle
        documentsDescription
        documentsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        documentsList {
          documentsListDocumentTitle
          documentsListDocumentLink
          documentsListDocumentImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
