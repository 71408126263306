import React from 'react'
import styled from 'styled-components'

import LazyImage from 'components/shared/lazyImage'
import { ImageDataLike } from 'gatsby-plugin-image'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type CataloguesItem = {
  img: ImageDataLike
  alt: string
  downloadLink: string
  title?: string
}

type Props = {
  catalogues: CataloguesItem[]
}

const Section = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 20px 0 0 0;

  ${({ theme }) => theme.media.lg.min} {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const TilesWrapper = styled.div`
  display: grid;

  ${({ theme }) => theme.media.lg.min} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const TileSingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;

  background-color: ${({ theme }) => theme.colors.gray100};

  // Gatsby image wrapper
  div {
    max-height: 100%;
  }

  border-bottom: 3px solid #cecece;

  ${({ theme }) => theme.media.lg.min} {
    position: relative;
    justify-content: flex-end;
    width: 100%;

    ${Text} {
      align-self: flex-start;
    }

    &:nth-child(odd) {
      border-bottom: 3px solid #cecece;
      border-right: 3px solid #cecece;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
`

const Tiles: React.FC<Props> = ({ catalogues }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <TilesWrapper>
        {catalogues.map(({ img, alt, downloadLink, title }, index) => (
          <TileSingleWrapper key={`${index}-${alt}`}>
            {title && (
              <Text
                size={16}
                weight={600}
                themecolor="black"
                margin="20px"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <LazyImage
                src={img}
                alt={alt}
                objectFit={lg ? 'contain' : 'cover'}
              />
            </a>
          </TileSingleWrapper>
        ))}
      </TilesWrapper>
    </Section>
  )
}

export default Tiles
